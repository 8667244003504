<template>
  <div>
    <c-tab
      ref="ctomissTab"
      type="horizon"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam.sync="popupParam"
          :investigation.sync="investigation"
          :heavyWorks.sync="heavyWorks"
          :attachInfo.sync="attachInfo"
          :contentHeight="contentHeight"
          :disabled.sync="disabled"
          :isOld.sync="isOld"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'investigationPlanDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaInvestigationPlanId: '',
        heaSurveyCategoryCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      splitter: 5,
      tab: 'info',
      investigation: {
        heaInvestigationPlanId: '',  // 근골격계 조사계획 일련번호
        plantCd: '',  // 사업장코드
        investigationPlanName: '',  // 조사계획명
        year: '',  // 심사년도
        heaInvestigationStepCd: '',  // 진행상태
        heaSurveyCategoryCd: '',  // 조사구분
        investigationStartDate: '',  // 조사시작일
        investigationEndDate: '',  // 조사종료일
        period: [],
        deptCd: '',  // 담당자 부서
        userId: '',  // 담당자 id
        investigationDeptCd: '',  // 조사부서
        agency: '',  // 조사기관
        remark: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        sops: [], // 단위작업
        sopTable: [], // 작업분류표
      },
      heavyWorks: [],
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'HEA_INVESTIGATION_PLAN',
        taskKey: '',
      },
      editable: true,
      detailUrl: '',
      heavyWorkUrl: '',
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.heaInvestigationPlanId)
    },
    disabled() {
      return this.investigation.heaInvestigationStepCd !== 'HI00000001';
    },
    tabItems() {
      let _items = [
        { name: 'info', icon: 'edit', label: 'LBLBASEINFO', component: () => import(`${'./investigationPlanInfo.vue'}`) },
      ]
      if (this.isOld) {
        _items = this.$_.concat(_items, [
          { name: 'job', icon: 'build', label: '부담작업', component: () => import(`${'./musculoskeletalSystemJob.vue'}`), },
          { name: 'worksheet', icon: 'format_list_numbered', label: '작업분류표', component: () => import(`${'./worksheet.vue'}`), },
        ])
      }
      return _items;
    },
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  watch: {
    'investigation.plantCd'() {
      this.getHeavyWorks();
    }
  },  
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.hea.muscleSystem.investigationPlan.get.url
      this.heavyWorkUrl = selectConfig.hea.heavyWork.list.url;
      // code setting
      // list setting
      this.getDetail();
      this.getHeavyWorks();
    },
    getDetail() {
      if (this.isOld) {    
        this.$http.url = this.$format(this.detailUrl, this.popupParam.heaInvestigationPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.investigation, _result.data)
          this.$set(this.investigation, 'period', [this.investigation.investigationStartDate, this.investigation.investigationEndDate])
          this.$set(this.attachInfo, 'taskKey', this.popupParam.heaInvestigationPlanId)
        },);
      } else {
        this.$set(this.investigation, 'heaSurveyCategoryCd', this.popupParam.heaSurveyCategoryCd)
        this.$set(this.investigation, 'heaInvestigationStepCd', 'HI00000001')
        this.$set(this.investigation, 'userId', this.$store.getters.user.userId)
        this.$set(this.investigation, 'deptCd', this.$store.getters.user.deptCd)
        this.$set(this.investigation, 'year', this.$comm.getThisYear())
      }
    },
    getHeavyWorks() {
      this.$http.url = this.heavyWorkUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.heavyWorks = _result.data;
      },);
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
  }
};
</script>
